import React, {useEffect, useState} from 'react';
import profile from './img/profile.jpg'
import axios from "axios";
function ScreenEight(props) {
    const [matches, setMatches] = useState([])
    useEffect(() => {
        const preloader = document.getElementById("preloaderq");

        const setPreloaderTransform = (value) => preloader.style.transform = `translateY(${value})`;

        // Скрываем прелоадер после загрузки страницы
        setTimeout(() => setPreloaderTransform('-100%'), 500);

        let id = ''
        /*global Telegram*/
        if (Telegram.WebApp.initDataUnsafe.user) {
            id = Telegram.WebApp.initDataUnsafe.user.id
        } else {
            id = '12345'
        }
        axios.get(process.env.REACT_APP_URL + '/userreactions/matches?id=' + id).then(response => {
            const data = response.data
            setMatches(data)
        }).catch()
    }, []);
    return (
        <div>  
            <div id="preloaderq">
                <div className="logo-container">
                    <div className="h1">TG Dating</div>
                </div>
            </div>

            <div className="app-wrapper">

                <section className="section app-header">
                    <div className="container">
                        <div className="header-relative-wrapper">
                            <div className="row header-row">
                                <div className="col-2">
                                    <a href="/screenSeven" className="header-btn btn btn-primary">
                                        <i className="bi bi-arrow-left"></i>
                                    </a>
                                </div>
                                <div className="col-8">
                                    <div className="header-logo">TG Dating</div>
                                </div>
                                <div className="col-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section app-body">
                    <div className="container">
                        <div className="position-relative d-flex gap-2 flex-column">
                            {matches.map(function (value, index, array) {
                                const photo = process.env.REACT_APP_URL + '/photos/' + value.photo.split('/').pop()
                                return <div className="chat-member">
                                    <div className="d-flex gap-3 align-items-center">
                                        <div className="chat-member__avatar">
                                            <img src={value.photo} alt=""/>
                                        </div>
                                        <div>
                                            <div className="chat-member__name"><a href="/screenEleven"
                                                                                  className="stretched-link">{value.name}</a>
                                            </div>
                                            <div className="chat-member__text">Lorem ipsum dolor sit amet consectetur
                                                adipisicing elit. Dicta qui possimus debitis tempora velit, assumenda
                                                itaque sint modi rerum aliquam quaerat natus voluptates. Vero tenetur
                                                doloribus ducimus a fuga nulla?
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chat-member__data">5 минут</div>
                                </div>
                            })}

                        </div>
                    </div>
                </section>


            </div></div>
    );
}

export default ScreenEight;