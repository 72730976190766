import React, {useEffect} from 'react';
import axios from "axios";
import {useNavigate} from "react-router-dom";


function StartScreen(props) {
    const navigate = useNavigate()
    useEffect(() => {
        /*global Telegram */
        if (Telegram.WebApp.initDataUnsafe.user) {
            axios.get(process.env.REACT_APP_URL + 'user/1' + Telegram.WebApp.initDataUnsafe.user.id).then(response => {
                navigate(`/screenFiveSix`)
            }).catch(() => {

            })

        } else {
            axios.get(process.env.REACT_APP_URL + '/user/12345').then(response => {
                navigate(`/screenFiveSix`)
            }).catch(() => {

            })
        }
    })
    return (
        <div>
        <section className="section app-header">
            <div className="container">
                <div className="header-relative-wrapper">
                    <div className="header-hero">
                        <div className="header-hero__title">TG Dating</div>
                        <div className="header-hero__subtitle">НАЙДИ СВОЮ ПОЛОВИНКУ</div>
                    </div>
                </div>
            </div>
        </section>
    <section className="section app-body" style={{backgroundImage: "url('img/bg-login.jpg')"}}>
        <div className="position-relative d-flex flex-column justify-content-end flex-fill py-5">
            <div className="container small-container">
                <div className="text-center">
                    <a href="/login" className="btn btn-primary w-100">Создать аккаунт</a>
                </div>
            </div>
        </div>
        <div className="app-copyright">
                <div className="container">
                    <div className="text-muted">Создавая аккаунт, Вы соглашаетесь с <a href="#">правилами
                        предоставления
                        услуг</a> на сервисе
                    </div>
                </div>
            </div>
        </section>
        </div>
    );
}

export default StartScreen;