import React, {useState} from 'react';
import axios from "axios";
import {useNavigate, useNavigation} from "react-router-dom";

function ScreenFour(props) {

    const navigate = useNavigate()
    const [file, setFile] = useState();
    function uploadPhoto(formData) {
        let id = ''
        /* global Telegram */
        if (Telegram.WebApp.initDataUnsafe.user) {
            id = Telegram.WebApp.initDataUnsafe.user.id
        } else {
            id = '12345'
        }
        axios.post(process.env.REACT_APP_URL + '/upload/' + id, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                console.log('File uploaded successfully!');
                navigate(`/screenFiveSix`)
            })
            .catch(error => {
                console.error('Error uploading file:', error);
            });
    }



    return (
        <div>  
            <div className="app-wrapper">
                <section className="section app-body">
                    <div className="position-relative d-flex flex-column justify-content-center flex-fill">
                        <div className="container small-container">
                            <div className="text-center">
                                <div className="mb-3">Загрузите своё фото:</div>
                                <form action={uploadPhoto}>
                                    <div className="d-flex flex-column gap-2 mb-4">
                                        <label htmlFor="file">
                                            <div className="field-photo-loader">
                                                <i className="bi bi-camera-fill"></i>
                                            </div>
                                            <input type="file" id="file" name="file" style={{display: "none"}}/>
                                        </label>
                                        <div className="small text-muted">Нажмите для выбора фото из галереи</div>
                                    </div>
                                    <button type="submit" className="btn btn-primary w-100">Готово!</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="app-copyright">
                        <div className="container">
                            <div className="text-muted">Создавая аккаунт, Вы соглашаетесь с <a href="#">правилами предоставления
                                услуг</a> на сервисе</div>
                        </div>
                    </div>
                </section>


            </div>
        </div>
    );
}

export default ScreenFour;